import Vue from "vue";
import App from "./App.vue";
import { i18n } from "./i18n";
import { store } from "@liteforex.com/lf-ui";
// This required for Cypress
import "whatwg-fetch";
import "@liteforex.com/lf-ui/dist/styles.css";
Vue.config.productionTip = false;
new Vue({
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
