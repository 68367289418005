import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "@liteforex.com/lf-ui/dist/locales/chart-ru.json";
Vue.use(VueI18n);
export const i18n = new VueI18n({
    locale: "ru",
    fallbackLocale: "ru",
    messages: {
        ru: messages,
    },
});
const loadedLanguages = ["ru"];
function setI18nLanguage(lang) {
    i18n.locale = lang;
    document.querySelector("html").setAttribute("lang", lang);
    return lang;
}
export function loadLanguageAsync(lang) {
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }
    return import(
    /* webpackChunkName: "lang-[request]" */ `@liteforex.com/lf-ui/dist/locales/${lang}.js`).then((messages) => {
        i18n.setLocaleMessage(lang, messages.default);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
    });
}
