import { io } from "socket.io-client";
import { dtoToRaw, } from "chart";
import { store } from "@liteforex.com/lf-ui";
import { isArray } from "lodash-es";
const baseUrl = "/_liteforex/ru/chart";
const _socket = io("https://node.litemarkets.com:44380", {
    path: "/app-cabinet/socket.io",
});
let order = 1;
const checkResponse = (data) => {
    if (!data || !("status" in data)) {
        throw new Error("Incorrect API response!");
    }
};
export const getBackendApi = (chartSymbol) => ({
    // I don't want to use callbacks or store socket instance in ShellState or somewhere else.
    // So I need this method. When chart loses connection, socket emits disconnect
    // Then socail.liteforex tres to reconnect and if reconnection was successful
    // socket emits connect.
    getFreeMargin() {
        return 1;
    },
    getLastQuotes() {
        return {};
    },
    getSymbolInformation() {
        return {};
    },
    getAccountInfo() {
        return {};
    },
    closeTrade(order) {
        console.log(order);
        setTimeout(() => {
            store.getters.chartApi.updateTradeStatus("close");
        }, 1000);
    },
    openTrade(volume, type, externalId, sl = 0, tp = 0) {
        console.log(volume, type, sl, tp);
        setTimeout(() => {
            order += 1;
            store.getters.chartApi.onTradeOpen({
                order,
                cmd: type,
                sl,
                tp,
                externalId,
                status: "open",
            });
        }, 100);
    },
    updateTrade(order, sl, tp) {
        console.log(order, sl, tp);
    },
    initSocketSubscriptions({ onConnect, onDisconnect, onPriceUpdate }) {
        _socket.on("disconnect", () => onDisconnect());
        _socket.on("connect", () => onConnect());
        _socket.on("c", (data) => {
            const [_, symbol, bid, ask, timestamp, volumeDif, sequenceNumber,] = data;
            onPriceUpdate({
                symbol,
                ask,
                bid,
                timestamp,
                volumeDif,
                sequenceNumber,
            });
        });
    },
    deinitSocketSubscriptions() {
        _socket.off("disconnect");
        _socket.off("connect");
        _socket.off("c");
    },
    getSymbolList() {
        const url = getUrl(`get-symbol-list`, {});
        return fetch(url).then((r) => {
            return r.json();
        });
    },
    getHistory(symbol, resolution, from, to, callback) {
        if (localStorage.getItem("returnNoDataResponses") === "true") {
            callback({ status: "error", code: "NO_DATA" });
            return;
        }
        symbol = encodeURIComponent(symbol);
        const params = {
            symbol,
            resolution,
            from,
            to,
        };
        const url = getUrl("get-history", params);
        fetch(url)
            .then((response) => response.json())
            .then((resp) => {
            callback(resp);
        });
    },
    subscribePriceUpdates(symbol) {
        // setInterval(
        //     () =>
        //         callback(
        //             1.2 + Math.random() * 0.01,
        //             0,
        //             DateTime.fromSeconds(DateTime.utc().toSeconds(), {
        //                 zone: "Europe/Athens",
        //             }).toSeconds(),
        //             600,
        //             symbol
        //         ),
        //     1000
        // );
        // callback(data[2], data[3], data[4], data[5], data[1]);
        _socket.emit("chart_subscribe", { server: 3, symbol });
    },
    unsubscribePriceUpdates(symbol) {
        _socket.emit("chart_unsubscribe", { server: 3, symbol });
    },
    async getChartStateDefault() {
        const defaultState = getParsedList()[0];
        if (!defaultState) {
            return {
                status: "error",
                code: "UNKNOWN",
            };
        }
        return {
            status: "success",
            data: defaultState,
        };
    },
    async getChartStateById(id) {
        const map = getChartStateMap();
        const state = map.get(id);
        if (state !== undefined) {
            localStorage.setItem("chart-state-id", JSON.stringify(state.stateId));
            return {
                status: "success",
                data: state,
            };
        }
        return {
            status: "error",
            code: "NOT_FOUND",
        };
    },
    async removeChartStateById(id) {
        const map = getChartStateMap();
        map.delete(id);
        localStorage.setItem("chart-state-list", JSON.stringify(Array.from(map.values())));
        return {
            status: "success",
        };
    },
    async removeChartStateDefault() {
        const map = getChartStateMap();
        const [_, ...rest] = Array.from(map.values());
        localStorage.setItem("chart-state-list", JSON.stringify(rest));
        return {
            status: "success",
        };
    },
    async saveChartState(newState) {
        const { getters } = store;
        const metaInfo = {
            creatorId: getters.userId || 0,
            isFavorite: false,
            isAvaliableByLink: false,
            creationDate: getters.currentChartStateCreationDate,
            resolution: getters.snapshot.resolution,
            link: "",
        };
        const stateToSave = {
            stateId: newState.stateId ? newState.stateId : Date.now(),
            ...newState,
            ...metaInfo,
        };
        const map = getChartStateMap();
        map.set(stateToSave.stateId, stateToSave);
        localStorage.setItem("chart-state-id", JSON.stringify(stateToSave.stateId));
        localStorage.setItem("chart-state-list", JSON.stringify(Array.from(map.values())));
        return {
            status: "success",
        };
    },
    async getChartStateList() {
        return {
            status: "success",
            data: getParsedList(),
        };
    },
    async favChartState(id, value) {
        const map = getChartStateMap();
        const targetState = map.get(id);
        if (targetState === undefined) {
            return {
                status: "error",
                code: "NOT_FOUND",
            };
        }
        localStorage.setItem("chart-state-list", JSON.stringify(Array.from(map.values())));
        return {
            status: "success",
            data: { stateId: id, isFavorite: value },
        };
    },
    async publishChartState(id, value) {
        const map = getChartStateMap();
        const targetState = map.get(id);
        if (targetState === undefined) {
            return {
                status: "error",
                code: "NOT_FOUND",
            };
        }
        localStorage.setItem("chart-state-list", JSON.stringify(Array.from(map.values())));
        return {
            status: "success",
            data: { stateId: id, isAvaliableByLink: value },
        };
    },
    async getUsersTrades(symbol, from, to) {
        const params = {
            symbol,
            from,
            to,
        };
        const url = getUrl("get-users-trades", params);
        const apiResp = await fetch(url).then((response) => response.json());
        checkResponse(apiResp);
        return apiResp;
    },
    async getUserInfo(id) {
        const url = getUrl("get-user-info", { id });
        const apiResp = await fetch(url).then((response) => response.json());
        checkResponse(apiResp);
        return apiResp;
    },
    async getUserListInfo(traderIdList) {
        const url = getUrlWithArray("get-user-list-info", "ids", traderIdList);
        const apiResp = await fetch(url).then((response) => response.json());
        checkResponse(apiResp);
        return apiResp;
    },
    // returns subscription id
    subscribeNewTrades(symbol, timeframe, callback) {
        return 42;
    },
    unsubscribeNewTrades(subscriptionId) { },
    async getTemplates() {
        await sleep(100);
        const templates = localStorage.getItem("templates");
        const templatesToResolve = templates === null
            ? []
            : JSON.parse(templates).map((t) => dtoToRaw(t));
        return {
            status: "success",
            data: templatesToResolve,
        };
    },
    async getTemplate(id) {
        const templatesString = localStorage.getItem("templates");
        if (templatesString === null) {
            return {
                status: "error",
                code: "NOT_FOUND",
            };
        }
        const templates = JSON.parse(templatesString);
        const idx = templates.findIndex((t) => t.id === id);
        return {
            status: "success",
            data: templates[idx],
        };
    },
    async saveTemplate(template) {
        const templatesString = localStorage.getItem("templates");
        let templates = [];
        let id = 0;
        if (templatesString !== null) {
            templates = JSON.parse(templatesString);
            const idx = templates.findIndex((t) => t.id === template.id);
            if (idx !== -1) {
                template = { ...template, date: Date.now() };
                templates[idx] = template;
                localStorage.setItem("templates", JSON.stringify(templates));
                return {
                    status: "success",
                    data: template,
                };
            }
            id = templates.length;
        }
        template = { ...template, date: Date.now(), id };
        templates.push(template);
        localStorage.setItem("templates", JSON.stringify(templates));
        return {
            status: "success",
            data: template,
        };
    },
    async deleteTemplate(id) {
        const templatesString = localStorage.getItem("templates");
        if (templatesString === null) {
            throw "templates not found";
        }
        const templates = JSON.parse(templatesString);
        const newTemplates = templates.filter((t) => t.id !== id);
        localStorage.setItem("templates", JSON.stringify(newTemplates));
        return {
            status: "success",
        };
    },
    async favTemplate(id, isFaved) {
        const templatesString = localStorage.getItem("templates");
        if (templatesString === null) {
            throw "templates not found";
        }
        const templates = JSON.parse(templatesString);
        const idx = templates.findIndex((t) => t.id === id);
        templates[idx].favorite = isFaved;
        templates[idx] = { ...templates[idx], date: Date.now() };
        localStorage.setItem("templates", JSON.stringify(templates));
        return {
            status: "success",
            data: templates[idx],
        };
    },
    async getTradeEvents(symbol, from, to) {
        const params = {
            symbol,
            from,
            to,
        };
        const url = getUrl("analytics-get-calendar", params);
        const apiResp = await fetch(url).then((response) => response.json());
        checkResponse(apiResp);
        return apiResp;
    },
    async getCountryList() {
        const url = `${baseUrl}/analytics-get-country-list`;
        const apiResp = await fetch(url).then((response) => response.json());
        checkResponse(apiResp);
        return apiResp;
    },
    async publishUserScript(meta) {
        const url = `${baseUrl}/indicator-save`;
        const formData = {
            name: meta.longName,
            short_name: meta.shortName,
            content: meta.content,
        };
        if ("id" in meta) {
            formData.id = meta.id;
        }
        const apiResp = await sendMultipartFormData(url, formData);
        checkResponse(apiResp);
        return {
            ...apiResp,
            data: parseIndicatorData(apiResp.data),
        };
    },
    async getUserScriptList() {
        const url = `${baseUrl}/indicator-list`;
        const apiResp = await fetch(url).then((response) => response.json());
        checkResponse(apiResp);
        const parsedData = apiResp.data
            ? apiResp.data.map(({ id, user_id, name, short_name, latest, date }) => ({
                scriptId: id.toString(),
                userId: user_id.toString(),
                longTitle: name,
                shortTitle: short_name || name,
                timestamp: date,
                latestVersionId: latest.toString(),
            }))
            : [];
        return {
            ...apiResp,
            data: parsedData,
        };
    },
    async getUserScriptById(id, version) {
        const params = {
            id,
        };
        if (version) {
            params.version = version;
        }
        const url = getUrl("indicator-get", params);
        const apiResp = await fetch(url).then((response) => response.json());
        checkResponse(apiResp);
        return {
            ...apiResp,
            data: parseIndicatorData(apiResp.data),
        };
    },
    async removeUserScript(id) {
        const params = {
            id,
        };
        const url = getUrl("indicator-delete", params);
        const apiResp = await fetch(url).then((response) => response.json());
        checkResponse(apiResp);
        return apiResp;
    },
    async renameUserScript(id, { longName, shortName }) {
        const params = {
            id,
        };
        const url = getUrl("indicator-rename", params);
        const apiResp = await sendMultipartFormData(url, {
            name: longName,
            short_name: shortName,
        });
        checkResponse(apiResp);
        return {
            ...apiResp,
            data: parseIndicatorData(apiResp.data),
        };
    },
});
function getUrl(methodName, params) {
    const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");
    const url = `${baseUrl}/${methodName}?${queryString}`;
    return url;
}
function getUrlWithArray(methodName, paramsLabel, paramPayload) {
    const queryString = paramPayload
        .map((item) => `${paramsLabel}[]=${item}`)
        .join("&");
    const url = `${baseUrl}/${methodName}?${queryString}`;
    return url;
}
function getParsedList() {
    const stateList = localStorage.getItem("chart-state-list");
    const parsedStateList = stateList !== null ? JSON.parse(stateList) : [];
    return isArray(parsedStateList) ? parsedStateList : [];
}
function getChartStateMap() {
    const parsedStateList = getParsedList();
    const map = new Map(parsedStateList.map((item) => [item.stateId, item]));
    return map;
}
async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
function sendMultipartFormData(url, data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => formData.append(key, value));
    return fetch(url, {
        method: "POST",
        body: formData,
    }).then((response) => response.json());
}
function parseIndicatorData(data) {
    const { source_code, id, name, short_name, user_id } = data;
    const versionList = source_code
        ? source_code.map(({ version_id, date, content }) => ({
            versionId: version_id.toString(),
            timestamp: date,
            sourceCode: content,
        }))
        : [];
    return {
        scriptId: id.toString(),
        userId: user_id.toString(),
        longTitle: name,
        shortTitle: short_name,
        versionList,
    };
}
