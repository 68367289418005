var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("LfChart", {
        attrs: {
          symbol: _vm.symbol,
          precision: 100000,
          serverApi: _vm.serverApi,
          locale: "ru",
          userId: 1,
          loaderSrc: "https://my.liteforex.com/svg/loader-v1.svg",
          callbacks: {
            showTraderStats: _vm.onShowUserStats,
            sendTraderPersonalMessage: _vm.onSendPersonalMessage,
            openSupport: _vm.onOpenSupport,
            openLogin: _vm.openLogin,
            openRegistration: _vm.openRegistration,
            openOtherSymbolWindow: _vm.openOtherSymbolWindow,
            applyChartState: _vm.applyChartState
          },
          templateId: _vm.initTemplateId,
          chartStateId: _vm.initChartStateId,
          chartSymbolType: "currency"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }