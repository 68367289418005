import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { LfChart } from "@liteforex.com/lf-ui";
import { getBackendApi } from "./backend-api";
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.symbol = "BTCUSD";
        this.serverApi = getBackendApi(this.symbol);
        this.isTemplateAutoApplyEnabled = false;
        this.isFullscreen = false;
        this.isRtl = false;
    }
    // Want to save this commented for development purposes
    created() {
        // document.getElementsByTagName("html")[0].classList.add("rtl");
    }
    mounted() {
        document.body.addEventListener("keydown", this.debugKeyDown);
    }
    beforeDestroy() {
        document.body.removeEventListener("keydown", this.debugKeyDown);
    }
    debugKeyDown(e) {
        if ((e.ctrlKey && e.altKey) === false) {
            return;
        }
        // Debug fullscreen cahnge
        if (e.key === "f") {
            this.isFullscreen = !this.isFullscreen;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.$store.commit("SET_FULLSCREEN", this.isFullscreen);
        }
        if (e.key === "r") {
            this.isRtl = !this.isRtl;
            if (this.isRtl) {
                document.dir = "rtl";
                document.getElementsByTagName("html")[0].classList.add("rtl");
            }
            else {
                document.dir = "ltr";
                document
                    .getElementsByTagName("html")[0]
                    .classList.remove("rtl");
            }
        }
    }
    onShowUserStats(traderId) { }
    onSendPersonalMessage(traderId) { }
    onOpenSupport() {
        console.log("onOpenSupport");
    }
    openLogin() { }
    openRegistration() { }
    openOtherSymbolWindow(symbol, template) {
        localStorage.setItem(`${symbol}-template`, JSON.stringify(template));
    }
    async applyChartState(id, symbol, openNewTab = true) {
        const response = await this.serverApi.getChartStateById(id);
        if (!response) {
            throw new Error(`Error: ${response}`);
        }
        const { status, data } = response;
        if (status === "success" && data) {
            localStorage.setItem("chart-state-id", JSON.stringify(data.stateId));
            document.location.reload();
            return;
        }
        throw new Error(`State not found ${id}`);
    }
    get initTemplateId() {
        const template = localStorage.getItem(`${this.symbol}-template`);
        if (template !== null && this.isTemplateAutoApplyEnabled === true) {
            const parsedTemplate = JSON.parse(template);
            return parsedTemplate.id;
        }
        return undefined;
    }
    get initChartStateId() {
        const id = localStorage.getItem("chart-state-id");
        if (id !== null) {
            return JSON.parse(id);
        }
        return 0;
    }
};
App = __decorate([
    Component({
        components: {
            LfChart,
        },
    })
], App);
export default App;
